import React, { useState, useRef, useEffect } from "react";
import { withRouter } from "../../utils/withRouter";
import "../../assets/scss/HotelSearchBox.scss";
import { Grid, Button } from "@material-ui/core";
import { parseDateForHotel } from "../../utils/date";
import clientV3 from "../../apolloClient";
import { AUTOCOMPLETE_SEARCH_QUERY } from "../../queries/hotelquery";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { commonHandleSearch } from "../../utils/hotelUtil";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import HomePassengerTypeWeb from "../../components/web/HomePassengerTypeWeb";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import LandmarkSelectField from "../../components/hotel/LandmarkSelectField";
import { IMG_CDN_URL } from "../../config/constants";

const HotelArrow = `${IMG_CDN_URL}/hotel/HotelArrow.png`;

const HotelSearchBox = ({
  match,
  history,
  location,
  selectedLanguage,
  isSearching = false,
  isCitizen,
  setIsCitizen,
  isInApp,
  isInWeb,
  fprefCheck,
  fpoffCheck,
  fbkCheck,
  fponCheck,
  landmark,
  setLandmark,
  distance,
  setDistance,
  handleAdvancedSearch,
  setShowEdit,
  setIsEditSearch,
}) => {
  let navigate = useNavigate();
  let params = useParams();
  const getCount = (i) => +params.adultChildRoom.split("-")[i];
  const [checkIn, setCheckIn] = useState(parseDateForHotel(params.checkIn));
  const [checkOut, setCheckOut] = useState(parseDateForHotel(params.checkOut));
  const [adult, setAdult] = useState(getCount(0));
  const [child, setChild] = useState(getCount(1));
  const [room, setRoom] = useState(getCount(2));

  const [show, setShow] = useState(false);
  const [hotels, setHotels] = useState([]);
  const [selectedItem, setSelectedItem] = useState({
    name: decodeURIComponent(params.city),
  });

  const hotelName = useRef();
  const { t } = useTranslation(["masterHomePage", "hotel"]);

  useEffect(() => {
    hotelName.current.value = selectedItem.name;
    return () => {};
  }, [selectedItem.name]);

  const fetchHotels = (searchParam) => {
    clientV3()
      .mutate({
        mutation: AUTOCOMPLETE_SEARCH_QUERY,
        variables: {
          input: {
            text: searchParam,
          },
        },
      })
      .then((res) => {
        //console.log(res);
        setShow(true);
        setHotels(res.data.hotelSearch);
      })
      .catch((err) => {
        // console.log(err);
        setShow(false);
        setHotels([]);
      });
  };

  const onPropertyChange = (e) => {
    setSelectedItem({
      name: e.target.value,
    });
    if (
      e.target.value &&
      e.target.value.trim() &&
      e.target.value.trim().length >= 3
    ) {
      fetchHotels(e.target.value.trim());
    } else {
      setShow(false);
      setHotels([]);
    }
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      if (hotels.length > 0) {
        setSelectedItem(hotels[0]);
        hotelName.current.value = hotels[0].name;
        setShow(false);
      }
    }
  };

  return (
    <>
      <div className="HotelSearchBox">
        <Grid
          container
          className="align-items-center second-row"
          style={{ gap: "17px", padding: "20px" }}
        >
          <Grid item xs={12}>
            <Grid
              container
              className="justify-content-center align-items-center"
            >
              <Grid
                item
                xs={3}
                className="d-flex align-items-center justify-content-end"
                style={{
                  paddingInline: "20px",
                  borderRight: "1px solid #D9D9D9",
                }}
              >
                <label className="search-label" htmlFor="checkOutDate">
                  {t("masterHomePage.myanmar_citizen") !==
                  "masterHomePage.myanmar_citizen"
                    ? t("masterHomePage.myanmar_citizen")
                    : "Myanmar Citizen"}
                </label>
                <div className="d-flex">
                  <div>
                    <FormControlLabel
                      id="citizen-yes"
                      label={
                        t("masterHomePage.yes_no.0") !==
                        "masterHomePage.yes_no.0"
                          ? t("masterHomePage.yes_no.0")
                          : "Yes"
                      }
                      name="myanmarCitizen"
                      value="yes"
                      color="primary"
                      control={<Radio fontSize="small" color="primary" />}
                      checked={isCitizen ? true : false}
                      onChange={() => setIsCitizen(true)}
                      // className={className ? className : ""}
                    />
                    {/* <input
                      type="radio"
                      id="citizen-yes"
                      name="myanmarCitizen"
                      value="yes"
                      checked={isCitizen ? true : false}
                      onChange={() => setIsCitizen(true)}
                    />
                    <label htmlFor="citizen-yes" className="search-label">
                      {t("masterHomePage.yes_no.0") !== "masterHomePage.yes_no.0"
                        ? t("masterHomePage.yes_no.0")
                        : "Yes"}
                    </label> */}
                  </div>
                  <div>
                    <FormControlLabel
                      id="citizen-no"
                      label={
                        t("masterHomePage.yes_no.1") !==
                        "masterHomePage.yes_no.1"
                          ? t("masterHomePage.yes_no.1")
                          : "No"
                      }
                      name="myanmarCitizen"
                      value="no"
                      color="primary"
                      control={<Radio fontSize="small" color="primary" />}
                      checked={!isCitizen ? true : false}
                      onChange={() => setIsCitizen(false)}
                      disabled={true}
                      // className={className ? className : ""}
                    />
                    {/* <input
                      type="radio"
                      id="citizen-no"
                      name="myanmarCitizen"
                      value="no"
                      checked={!isCitizen ? true : false}
                      onChange={() => setIsCitizen(false)}
                    />
                    <label htmlFor="citize-no" className="search-label">
                      {t("masterHomePage.yes_no.1") !== "masterHomePage.yes_no.1"
                        ? t("masterHomePage.yes_no.1")
                        : "No"}
                    </label> */}
                  </div>
                </div>
              </Grid>
              <Grid item xs={3} style={{ paddingInline: "20px" }}>
                <HomePassengerTypeWeb
                  params={{
                    adult: adult,
                    child: child,
                    room: room,
                  }}
                  handleValueChange={(val, name) => {
                    name === "adult" && setAdult(val);
                    name === "child" && setChild(val);
                    name === "room" && setRoom(val);
                  }}
                  pageType="hotelSearch"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2} className="city-date-container">
              <Grid item xs={6}>
                <input
                  className="search-input city"
                  type="text"
                  placeholder={
                    t("hotel.cities_property_name") !==
                    "hotel.cities_property_name"
                      ? t("hotel.cities_property_name")
                      : "Cities/property name"
                  }
                  id="propertyName"
                  name="propertyName"
                  onChange={onPropertyChange}
                  ref={hotelName}
                  autoComplete="off"
                  // onKeyDown={e => {
                  //   handleKeyDown(e);
                  // }}
                />
              </Grid>
              <Grid item xs={3}>
                <div className="check-in-date">
                  <label className="search-label" htmlFor="checkInDate">
                    {t("hotel.check_in_date") !== "hotel.check_in_date"
                      ? t("hotel.check_in_date")
                      : "Check-in date"}
                  </label>
                  <DatePicker
                    className="search-input"
                    id="checkInDate"
                    name="checkInDate"
                    dateFormat="EEEE d MMMM yyyy"
                    selected={checkIn}
                    onChange={(date) => {
                      setCheckIn(date);
                      setCheckOut(
                        parseDateForHotel(moment(date).add(1, "days")),
                      );
                    }}
                  />
                  {/* <IconWrapper Icon={EventRounded} className="mt-5px">
                  </IconWrapper> */}
                </div>
              </Grid>
              <Grid item xs={3}>
                <div className="check-out-date">
                  <label className="search-label" htmlFor="checkOutDate">
                    {t("hotel.check_out_date") !== "hotel.check_out_date"
                      ? t("hotel.check_out_date")
                      : "Check-out date"}
                  </label>
                  <DatePicker
                    className="search-input"
                    id="checkOutDate"
                    name="checkOutDate"
                    dateFormat="EEEE d MMMM yyyy"
                    minDate={checkIn}
                    selected={checkOut}
                    onChange={(date) => setCheckOut(date)}
                  />
                </div>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={2}
              className="justify-content-end"
              style={{ marginTop: 14 }}
            >
              <Grid item xs={3}>
                <Button
                  className="btn-bold"
                  id="search-btn"
                  variant="contained"
                  fullWidth
                  onClick={() => {
                    setShowEdit(false);
                    commonHandleSearch(
                      isCitizen,
                      adult,
                      child,
                      room,
                      checkIn,
                      checkOut,
                      match,
                      selectedItem,
                      navigate,
                      selectedLanguage,
                      isInApp,
                      isInWeb,
                      hotels,
                      // fprefCheck,
                      // fpoffCheck,
                      // fbkCheck,
                      // fponCheck,
                    );
                    setIsEditSearch(true);
                  }}
                  disabled={isSearching}
                >
                  {/* <SearchRounded className="h-icon white-icon" />{" "} */}
                  {t("masterHomePage.search") !== "masterHomePage.search"
                    ? t("masterHomePage.search")
                    : "Search"}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          className="align-items-center third-row"
          style={{
            padding: "10px 20px 25px 20px",
          }}
        >
          <h3
            style={{
              fontWeight: 700,
              fontSize: "14px",
              marginBottom: 15,
              color: "#000",
            }}
          >
            {t("hotel.advanced_search", { ns: "hotel" }) !==
            "hotel.advanced_search"
              ? t("hotel.advanced_search", { ns: "hotel" })
              : "Advanced Search"}{" "}
            <span
              style={{ color: "#42AEEF", fontSize: "11px", marginLeft: 10 }}
            >
              {t("hotel.advanced_search_info", { ns: "hotel" }) !==
              "hotel.advanced_search_info"
                ? t("hotel.advanced_search_info", { ns: "hotel" })
                : "i. Search for hotels within selected distance of selected landmark."}
            </span>
          </h3>
          <Grid item xs={12}>
            <Grid container spacing={2} className="align-items-center">
              <Grid item xs={6}>
                {/* <label className="search-label">
                  {t("hotel.destination_landmark") !==
                  "hotel.destination_landmark"
                    ? t("hotel.destination_landmark", { ns: "hotel" })
                    : "Destination/Landmark"}
                </label> */}
                <LandmarkSelectField
                  value={landmark}
                  handleChange={setLandmark}
                />
              </Grid>
              <Grid item xs={3}>
                <div className="distance">
                  <label className="search-label">
                    Distance (km){" "}
                    {t("hotel.within") !== "hotel.within"
                      ? t("hotel.within")
                      : "within"}
                  </label>
                  <input
                    className="search-input"
                    type="number"
                    id="distance"
                    name="distance"
                    value={distance}
                    onChange={(e) => setDistance(e.target.value)}
                    maxLength="1"
                    // max="9"
                    placeholder="0.0 km"
                  />
                </div>
              </Grid>
              <Grid item xs={3}>
                <Button
                  className="btn-bold"
                  id="search-btn"
                  variant="outlined"
                  fullWidth
                  onClick={() => {
                    setShowEdit(false);
                    handleAdvancedSearch(landmark, distance);
                  }}
                  disabled={isSearching || !landmark || !distance}
                  style={{
                    color: !landmark || !distance ? "#fff" : "",
                    opacity: !landmark || !distance ? 0.5 : 1,
                  }}
                >
                  {t("masterHomePage.search") !== "masterHomePage.search"
                    ? t("masterHomePage.search")
                    : "Search"}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
      {/* <HotelListPopup
        show={show}
        setShow={setShow}
        hotels={hotels}
        setSelectedItem={setSelectedItem}
        hotelName={hotelName}
        setEmptyClickFlag={val => setEmptyClickFlag(val)}
        emptyClickFlag={emptyClickFlag}
      /> */}
    </>
  );
};

export default withRouter(HotelSearchBox);
