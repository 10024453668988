import React, { useState, useEffect, useCallback } from "react";
import Select from "react-select";
import { useLazyQuery } from "@apollo/react-hooks";
import { GET_LANDMARKS } from "../../queries/hotelquery";
import { debounce } from "lodash";
import { DebounceForSelect } from "../../utils/debounceSelect";
import "../../assets/scss/CommonSelect.scss";

const LandmarkSelectField = ({
  name,
  value,
  handleChange,
  arr,
  placeholder,
}) => {
  const [searchVal, setSearchVal] = useState("");
  const [searchText, setSearchText] = useState("Please search for company...");
  const [flag, setFlag] = useState(true);
  const [optionsArr, setOptionsArr] = useState([]);

  const [getLandmarks] = useLazyQuery(GET_LANDMARKS, {
    fetchPolicy: "network-only",
    onCompleted: (res) => {
      let arr =
        res.flymyaLandmark.data.length > 0
          ? res.flymyaLandmark.data.map((option) => ({
              value: option.name,
              label: option.name,
              valId: option.id,
            }))
          : [];

      arr.length === 0 && setSearchText("No result found.");
      setOptionsArr(arr);
    },
    onError: (err) => {
      console.log(err);
    },
  });

  const getOptions = useCallback(
    (value) => {
      getLandmarks({
        variables: {
          filter:
            value === ""
              ? []
              : [
                  {
                    field: "name",
                    operator: "SW",
                    value: value,
                  },
                ],
          orderBy: [
            {
              column: "id",
              order: "DESC",
            },
          ],
          first: 20,
          page: 1,
        },
      });
    },
    [getLandmarks],
  );

  useEffect(() => {
    flag && getOptions(searchVal);
    setFlag(false);
  }, [flag, getOptions, searchVal]);

  return (
    <Select
      options={optionsArr}
      filterOption={() => {
        return true;
      }}
      name={name}
      value={value ? { value: value, label: value } : ""}
      onChange={(e) => {
        handleChange && handleChange(e ? e.value : "");
      }}
      className="common-select landmark-select"
      onInputChange={(val) =>
        DebounceForSelect(
          val,
          setOptionsArr,
          setSearchVal,
          setSearchText,
          getOptions,
        )
      }
      clearable={false}
      onBlur={() => {}}
      placeholder={
        placeholder ? placeholder : "Search Destination/Landmark ..."
      }
      noResultsText={searchText}
      onFocus={() =>
        searchText === "No result found." &&
        optionsArr.length === 0 &&
        setSearchText("Please search for company...")
      }
    />
  );
};

export default LandmarkSelectField;
