/* eslint-disable prettier/prettier */
import React, { useState, useContext, useEffect } from "react";
import {
  Grid,
  Modal,
  Button,
  Popover,
  Tooltip,
  TextField,
} from "@mui/material";
import closeIcon from "../../../assets/images/closemodal.png";
import "./EditCustomerNameModal.scss";
import { checkInWeb } from "../../../utils/checkurl";
import moment from "moment";
import styled from "styled-components";
import { IS_CUSTOMER_VAL } from "../../../config/typecheck";
import {
  LOGIN_PEOPLE_DETAIL,
  LOGIN_USER_ID,
  LOGIN_USER_PEOPLE,
} from "../../../config/constants";
import { CREATE_ARRANGER } from "../../../queries/loginuserquery";
import clientV3 from "../../../apolloClient";

// const Banner = lazy(() =>
//   retry(() => import("../../components/CovidAlertBanner")),
// );

const EditCustomerNameOtherModal = ({
  showUpdateNameModal,
  setShowUpdateNameModal,
  values,
  setFieldValue,
  data,
  t,
  setOpen,
  successShow,
  setSuccessShow,
  setError,
  type,
}) => {
  const createArranger = (values) => {
    let pid = localStorage.getItem(LOGIN_USER_PEOPLE);
    let loginId = localStorage.getItem(LOGIN_USER_ID);
    let commonInput = {
      id: pid ? pid : null,
      login_id: Number(loginId),
      prefix: values.title,
      first_name: values.name,
      last_name: values.last_name,
    };
    clientV3()
      .mutate({
        mutation: CREATE_ARRANGER,
        variables: {
          input: commonInput,
        },
      })
      .then((res) => {
        //update login_people_detail data in localStorage
        let strData = localStorage.getItem(LOGIN_PEOPLE_DETAIL)
          ? JSON.parse(localStorage.getItem(LOGIN_PEOPLE_DETAIL))
          : null;
        localStorage.setItem(
          LOGIN_PEOPLE_DETAIL,
          JSON.stringify({
            ...strData,
            prefix: res?.data?.createArranger?.user?.arranger?.prefix,
            first_name: res?.data?.createArranger?.user?.arranger?.first_name,
            last_name: res?.data?.createArranger?.user?.arranger?.last_name,
          }),
        );

        //update userData data in localStorage
        let userData = localStorage.getItem("userData")
          ? JSON.parse(localStorage.getItem("userData"))
          : null;
        let updateUserData = {
          ...userData,
          arranger: {
            ...userData.arranger,
            prefix: res?.data?.createArranger?.user?.arranger?.prefix,
            first_name: res?.data?.createArranger?.user?.arranger?.first_name,
            last_name: res?.data?.createArranger?.user?.arranger?.last_name,
          },
        };
        localStorage.setItem("userData", JSON.stringify(updateUserData));

        setSuccessShow("Arranger Name is updated successfully");
        setOpen(true);
        setShowUpdateNameModal(false);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((err) => {
        setError("Arranger Name update failed.");
      });
  };
  return (
    <React.Fragment>
      <Modal
        className="editCustomerNameModal"
        open={showUpdateNameModal}
        id="reliefModal"
        onClose={(event, reason) => {
          if (reason === "backdropClick" && reason !== "escapeKeyDown") {
            setShowUpdateNameModal(false);
          }
        }}
      >
        <Grid
          className="confirmationBox contactUsModal customerContainer"
          style={{
            width: "auto",
            minWidth: type === "mobile" ? "auto" : "550px",
            backgroundColor: "#fff",
            outline: "none",
            flexDirection: "column",
          }}
          container
        >
          <div style={{ paddingBottom: "20px" }}>
            <div
              style={{
                padding: "20px",
              }}
            >
              <div>
                <h1 style={{ textAlign: "left", fontSize: 18 }}>
                  Profile Name Update?
                </h1>
                <p
                  className="delete-question"
                  style={{
                    lineHeight: "24px",
                    textAlign: "left",
                    // fontSize: 14,
                  }}
                >
                  {t("masterPassengerInformation.name_update_confirm_text") !==
                  "masterPassengerInformation.name_update_confirm_text"
                    ? t("masterPassengerInformation.name_update_confirm_text")
                    : "Do you want to update your name in your profile"}{" "}
                  {t("masterPassengerInformation.from") !==
                  "masterPassengerInformation.from"
                    ? t("masterPassengerInformation.from")
                    : "from"}
                  <span style={{ display: "block" }}>
                    <span style={{ color: "#00AEEF" }}>
                      {data?.arranger?.first_name} {data?.arranger?.last_name}
                    </span>{" "}
                    {t("masterPassengerInformation.to") !==
                    "masterPassengerInformation.to"
                      ? t("masterPassengerInformation.to")
                      : "to"}{" "}
                    <span style={{ color: "#00AEEF" }}>
                      {values.name} {values.last_name}
                    </span>{" "}
                    ?
                  </span>
                </p>
              </div>
              <div>
                <Button
                  className="delete-no"
                  style={{
                    // float: "right",
                    marginRight: 10,
                    backgroundColor: "#ddd",
                    color: "#2d2d2d",
                  }}
                  onClick={() => {
                    setShowUpdateNameModal(false);
                    if (data) {
                      setFieldValue("firstName", data?.arranger?.first_name);
                      setFieldValue("lastName", data?.arranger?.last_name);
                      setFieldValue(
                        "gender",
                        data?.arranger?.prefix?.split(".")?.[0],
                      );
                    }
                  }}
                >
                  No
                </Button>
                <Button
                  className="delete-yes"
                  style={{
                    // float: "right",
                    marginRight: 10,
                    backgroundColor: "rgb(0, 174, 239)",
                    color: "#fff",
                    border: "0.5px solid rgb(0, 174, 239)",
                  }}
                  onClick={() => {
                    createArranger(values);
                  }}
                >
                  Yes
                </Button>
              </div>
            </div>
          </div>
        </Grid>
        {/* <Grid
          className="confirmationBox contactUsModal customerContainer"
          style={{
            width: "auto",
            minWidth: type === "mobile" ? "auto" : "550px",
            backgroundColor: "#fff",
            outline: "none",
            flexDirection: "column",
          }}
          container
        >
          <div className="close-wrap">
            <img
              src={closeIcon}
              alt="close"
              onClick={() => setShowUpdateNameModal(false)}
              className="closeImg"
            />
          </div>
          <div style={{ display: "flex", flexDirection: "row", gap: 5 }}>
            <Grid item sm={2}>
              <span className="fieldTitle" style={{ float: "left" }}>
                {t("masterPassengerInformation.titles") !==
                "masterPassengerInformation.titles"
                  ? t("masterPassengerInformation.titles")
                  : "Title"}
              </span>
              <br />
              <select
                className="selectBox"
                name="gender"
                value={gender}
                onChange={(e) => {
                  setGender(e.target.value);
                }}
              >
                <option value="Mr">
                  {t("masterPassengerInformation.mr") !==
                  "masterPassengerInformation.mr"
                    ? t("masterPassengerInformation.mr")
                    : "Mr."}
                </option>
                <option value="Mrs">
                  {t("masterPassengerInformation.mrs") !==
                  "masterPassengerInformation.mrs"
                    ? t("masterPassengerInformation.mrs")
                    : "Mrs."}
                </option>
                <option value="Ms">
                  {t("masterPassengerInformation.ms") !==
                  "masterPassengerInformation.ms"
                    ? t("masterPassengerInformation.ms")
                    : "Ms."}
                </option>
              </select>
            </Grid>
            <Grid item sm={5} className={""}>
              <span className="fieldTitle" style={{ float: "left" }}>
                {`First + Middle Name`}
                {firstName === "" && `*`}
              </span>
              <TextField
                name="firstName"
                value={firstName}
                onChange={(e) => {
                  setFirstName(e.target.value);
                }}
                variant="standard"
                required
                inputProps={{
                  "data-lpignore": "true",
                }}
              />
            </Grid>
            <Grid item sm={5} className={""}>
              <span className="fieldTitle" style={{ float: "left" }}>
                {t("masterPassengerInformation.name.1") !==
                "masterPassengerInformation.name.1"
                  ? t("masterPassengerInformation.name.1")
                  : "Last Name"}
                {lastName === "" && `*`}
              </span>
              <TextField
                name="lastName"
                value={lastName}
                onChange={(e) => {
                  setLastName(e.target.value);
                }}
                variant="standard"
                inputProps={{
                  "data-lpignore": "true",
                }}
              />
            </Grid>
          </div>
          <div>
            <Button
              id="fl-customer-btn-continue"
              color="primary"
              variant="contained"
              className="btnContinue pull-right flightCustomerDetailContinue"
              onClick={() => {
                createArranger(values);
              }}
            >
              Update
            </Button>
          </div>
        </Grid> */}
      </Modal>
    </React.Fragment>
  );
};

const NewEmailStyle = styled.div`
  width: 50%;
  .email {
    display: flex;
    font-size: 14px;
    align-items: center;
    span,
    input {
      outline: none;
    }
    input {
      padding: 5px;
      border: 1px solid hsl(0, 0%, 80%);
      border-radius: 5px;
    }
  }
  .email {
    margin-bottom: 5px;
    input {
      margin-left: 10px;
    }
    button {
      background: rgb(0, 174, 239);
      color: #fff;
      font-size: 11px;
      margin-top: 2px;
      margin-left: 10px;
    }
  }
  .buttonDiv {
    text-align: right;
    button {
      background: rgb(0, 174, 239);
      color: #fff;
      font-size: 11px;
    }
  }
`;

const ChangePassBtn = styled.button`
  font-size: 10px;
  cursor: pointer;
  background: rgb(0, 174, 239);
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 0.875rem;
  padding: 10px 25px;
  margin-top: 5px;
  max-width: 180px;
`;

export default EditCustomerNameOtherModal;
