import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";

// const translationWorker = new Worker(
//   new URL("./worker/translationWorker.js", import.meta.url),
// );
const translationWorker = new Worker(
  new URL("./worker/translationWorker.js", import.meta.url),
);

// translationWorker.postMessage({
//   url: "https://example.com/locales/en/masterHomePage.json",
// }); // Send message to the worker

translationWorker.onmessage = function (e) {
  const { success, data, error } = e.data;
  if (success) {
    console.log("Data received from worker:", data); // Ensure the main thread gets the message back
  } else {
    console.error("Error from worker:", error);
  }
};

i18n.use(Backend).use(initReactI18next);

i18n.init({
  lng: "en", // Set the initial language
  fallbackLng: false, // Disable fallback language fetching
  load: "currentOnly", // Only load the current language's translations
  ns: [
    "masterHomePage",
    "masterHomeSecondPage",
    "b2c",
    "travelServices",
    "paymentPageThree",
    "masterPassengerInformationTwo",
    "auth",
    "balance",
  ], // Define all namespaces
  defaultNS: "masterHomePage", // Set the default namespace
  backend: {
    loadPath: `https://cdn.flymya.com/locales/{{lng}}/{{ns}}.json`,
    request: (options, url, payload, callback) => {
      // Extract lng and ns from the URL using a regular expression
      const urlParts = url.match(/locales\/([^\/]+)\/([^\/]+)\.json/);
      const lng = urlParts ? urlParts[1] : undefined;
      const ns = urlParts ? urlParts[2] : undefined;

      if (!lng || !ns) {
        console.error(
          "Language or namespace could not be extracted from the URL.",
        );
        callback(new Error("Language or namespace is missing"), {
          status: 500,
        });
        return;
      }

      fetch(url)
        .then((response) => {
          if (!response.ok) {
            throw new Error(`Failed to load from ${url}`);
          }
          return response.json();
        })
        .then((data) => {
          callback(null, { status: 200, data: JSON.stringify(data) });
        })
        .catch((error) => {
          console.warn(`Primary URL failed: ${url}`, error);

          // Construct fallback URL using the extracted lng and ns
          const fallbackUrl = `https://v4-be.flymya.com/${lng}/${ns}.json`;
          fetch(fallbackUrl)
            .then((response) => {
              if (!response.ok) {
                throw new Error(
                  `Failed to load from fallback URL: ${fallbackUrl}`,
                );
              }
              return response.json();
            })
            .then((data) => {
              callback(null, { status: 200, data: JSON.stringify(data) });
            })
            .catch((fallbackError) => {
              console.error(
                `Fallback URL failed: ${fallbackUrl}`,
                fallbackError,
              );
              callback(fallbackError, { status: 500 });
            });
        });
    },
  },

  // backend: {
  //   loadPath:
  //     process.env.REACT_APP_TRACK_RELEASE_STAGE === "development"
  //       ? `https://v3-be-public-staging.s3.ap-southeast-1.amazonaws.com/locales/{{lng}}/{{ns}}.json`
  //       : "https://cdn.flymya.com/locales/{{lng}}/{{ns}}.json",

  //   request: (options, url, payload, callback) => {
  //     // Post the URL to the Web Worker
  //     translationWorker.postMessage({ url });

  //     // Listen for the response from the Web Worker
  //     translationWorker.onmessage = function (e) {
  //       const { success, data, error } = e.data;

  //       if (success) {
  //         console.log("Worker successfully fetched data:", data); // Log to check fetched data
  //         callback(null, { status: 200, data: JSON.stringify(data) });
  //       } else {
  //         console.error("Error from worker:", error); // Log error
  //         callback(error, { status: 500 });
  //       }
  //     };

  //     // Handle any errors from the worker
  //     translationWorker.onerror = function (err) {
  //       console.error("Worker encountered an error:", err.message);
  //       callback("Error loading translations", { status: 500 });
  //     };
  //   },
  // },
});

export default i18n;
