/* eslint-disable no-unused-expressions */
/* eslint-disable no-unused-vars */
import React, { lazy, retry, useEffect, useState, useContext } from "react";
import { Grid, Modal, Button, TextField, Checkbox } from "@mui/material";
import {
  CALL_CENTER_PHONENO,
  CC_PHONENO_LINK,
  IS_B2B,
} from "../../config/constants";
import { isMobile } from "react-device-detect";
import "../../assets/scss/PassengerModalMobile.scss";
import {
  B2BCLASSTYPE_B_ID,
  B2BCLASSTYPE_N_ID,
  B2BCLASSTYPE_C_ID,
  IMG_CDN_URL,
} from "../../config/constants";
import { formatDateDayNameAndMonth } from "../../utils/date";
import { checkInWeb } from "../../utils/checkurl";
import FlymyaTravelInfoText from "../../components/FlymyaTravelnfoText";
import { GorgiasContext } from "../../GorgiasContext";
import { withFormik } from "formik";
import PhonePrefix from "../../components/inputs/PhonePrefix";
import HotelValidationSchema from "./HotelValidationSchema";
import { compose } from "recompose";
import { HotelPassengerFormMobileStyles } from "./styles/HotelPassengerFormMobileStyle";
import { getClientTag } from "../../utils/checkurl";
import { WAVEMONEY, KBZ } from "../../config/inappclients";
import { KBZSC_IN_WEB, VIBER_IN_WEB } from "../../config/inwebclients";
import { useTranslation } from "react-i18next";

const GrayCircleIcon = `${IMG_CDN_URL}/insider/gray_circle.svg`;
const GreenCircleIcon = `${IMG_CDN_URL}/insider/green_circle.svg`;
const closeIcon = `${IMG_CDN_URL}/relief_flight_close_icon.png`;
// const Banner = lazy(() =>
//   retry(() => import("../../components/CovidAlertBanner")),
// );
const HotelPassengerFormMobile = ({
  values,
  setFieldValue,
  errors,
  touched,
  handleSubmit,
  isSubmitting,
  clientCss,
  searchInfo,
  productDetail,
  showContactUsModal,
  setShowContactUsModal,
  type,
}) => {
  const [isInWeb] = useState(checkInWeb(window.location.pathname));
  const { updateGorgiasState } = useContext(GorgiasContext);
  const classes = HotelPassengerFormMobileStyles();
  const [clientTag] = useState(getClientTag(window.location.search));
  const isB2B =
    localStorage.getItem(IS_B2B) && localStorage.getItem(IS_B2B) === "yes"
      ? true
      : false;
  const { t } = useTranslation(["travelServices", "b2c"]);

  useEffect(() => {
    if (values?.phonePrefix === "95" && !isB2B) {
      setFieldValue("isCheckSMS", true);
      setFieldValue("isCheckEmail", false);
    } else {
      setFieldValue("isCheckSMS", false);
      setFieldValue("isCheckEmail", true);
    }
  }, [isB2B, setFieldValue, values?.phonePrefix]);

  let prefill_msg = "";
  if (type) {
    type === "flight"
      ? (prefill_msg = `Hi, i'm interested in ${productDetail?.company} flight (${productDetail?.external_id}) from ${productDetail?.departure_time}to ${productDetail?.arrival_time} on ${productDetail?.departure_date} with starting price of ${productDetail?.fareClasses[0].or} ${productDetail?.fareClasses[0].currency}. Can you help me?`)
      : type === "noflight"
      ? (prefill_msg =
          searchInfo?.endDate !== "0"
            ? `Hi, i'm interested in ${searchInfo.fromIata} - ${searchInfo.toIata} | ${searchInfo.startDate} -> ${searchInfo.endDate}|${searchInfo.tripType}. Can you help me?`
            : `Hi, i'm interested in ${searchInfo.fromIata} - ${searchInfo.toIata} |${searchInfo.startDate} |${searchInfo.tripType}. Can you help me?`)
      : type === "bus"
      ? (prefill_msg = `Hi, i'm interested in ${searchInfo?.operator_name_en} | ${searchInfo?.source_name_en} - ${searchInfo?.destination_name_en} | Departure Date/Time: ${searchInfo?.departure_date_time} | Arrival Date/Time: ${searchInfo?.est_arrival_date_time} | ${searchInfo?.fare_breakdowns?.gb} ${searchInfo?.currency}. Can you help me?`)
      : type === "hotel"
      ? (prefill_msg = searchInfo)
      : "";
  }

  const handleViewport = () => {
    if (window.innerWidth < 780) {
      updateGorgiasState(false);
    }
  };

  return (
    <React.Fragment>
      <Modal
        className="model-passenger"
        open={showContactUsModal}
        id="reliefModal"
        onClose={(event, reason) => {
          window.GorgiasChat && window.GorgiasChat.close();
          if (reason === "backdropClick" && reason !== "escapeKeyDown") {
            setShowContactUsModal(false);
          }
        }}
      >
        <Grid
          container
          item={true}
          xs={12}
          className={classes.insurancePassengerInfo}
        >
          <Grid item xs={12} sx={{ mb: 3 }}>
            <span
              style={{
                fontSize: 14,
                width: "100%",
              }}
            >
              Number Of Guest
            </span>
            <select
              name="guest-no"
              onChange={(e) => setFieldValue("guest_no", e.target.value)}
              required
              defaultValue="1"
              className="noerror-input"
              style={{ width: "100%" }}
            >
              <option defaultValue={true} value="1">
                1
              </option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
            </select>
          </Grid>
          <Grid item xs={12} sx={{ mb: 3 }}>
            <span
              style={{
                fontSize: 14,
                width: "100%",
              }}
            >
              Duration Of Stay
            </span>
            <select
              name="duration-of-stay"
              onChange={(e) =>
                setFieldValue("duration_of_stay", e.target.value)
              }
              required
              defaultValue="1 Month"
              className="noerror-input"
              style={{ width: "100%" }}
            >
              <option defaultValue={true} value="1 Month">
                1 Month
              </option>
              <option value="2 Month">2 Month</option>
              <option value="3 Month">3 Month</option>
              <option value="4 Month">4 Month</option>
              <option value="5 Month">5 Month</option>
              <option value="6 Month">6 Month</option>
            </select>
          </Grid>
          <Grid item xs={12} sx={{ mb: 3 }}>
            <span
              style={{
                fontSize: 14,
                fontWeight: 700,
                color: "rgb(0, 174, 239)",
                width: "100%",
              }}
            >
              Traveler Name
            </span>
          </Grid>
          <Grid item xs={3}>
            <select
              name="title"
              onChange={(e) => setFieldValue("title", e.target.value)}
              required
              defaultValue="Mr"
              className="noerror-input"
            >
              <option defaultValue={true} value="Mr">
                Mr.
              </option>
              <option value="Mrs">Mrs.</option>
              <option value="Ms">Ms.</option>
            </select>
          </Grid>
          <Grid item xs={9} className={classes.firstName}>
            <TextField
              name="name"
              placeholder={
                t("travelServices.travel_services_customer_name") !==
                "travelServices.travel_services_customer_name"
                  ? t("travelServices.travel_services_customer_name")
                  : "First Name + Middle Name *"
              }
              value={values.name || ""}
              onChange={(e) => setFieldValue("name", e.target.value)}
              required
              inputProps={{
                "data-lpignore": "true",
              }}
              className={
                errors.name && touched.name ? "error-input" : "noerror-input"
              }
              variant="standard"
            />
          </Grid>
          <Grid item xs={12} className={classes.lastName}>
            <TextField
              name="lastName"
              placeholder={
                t("travelServices.travel_services_customer_last_name") !==
                "travelServices.travel_services_customer_last_name"
                  ? t("travelServices.travel_services_customer_last_name")
                  : "Last Name *"
              }
              value={values.last_name || ""}
              onChange={(e) => setFieldValue("last_name", e.target.value)}
              required
              inputProps={{
                "data-lpignore": "true",
              }}
              className={
                errors.last_name && touched.last_name
                  ? "error-input"
                  : "noerror-input"
              }
              variant="standard"
            />
          </Grid>
          <Grid item xs={3} className={classes.phonePrefix}>
            <PhonePrefix
              name="phonePrefix"
              value={values.phonePrefix || ""}
              prefixValue={values.phonePrefix}
              handlePhonePrefix={(value) => setFieldValue("phonePrefix", value)}
              handlePhoneFlag={(value) =>
                setFieldValue("phoneCountryFlag", value)
              }
              phoneCountryFlag={values.phoneCountryFlag}
            />
          </Grid>
          <Grid item xs={9} className={classes.phoneNumber}>
            <TextField
              name="phone"
              placeholder={
                t("travelServices.travel_services_customer_phone") !==
                "travelServices.travel_services_customer_phone"
                  ? t("travelServices.travel_services_customer_phone")
                  : "Phone *"
              }
              value={values.phone || ""}
              onChange={(e) => setFieldValue("phone", e.target.value)}
              required
              inputProps={{
                "data-lpignore": "true",
              }}
              className={
                errors.phone && touched.phone ? "error-input" : "noerror-input"
              }
              variant="standard"
            />
          </Grid>
          <Grid item xs={12} className={classes.email}>
            <TextField
              name="email"
              placeholder={
                t("travelServices.travel_services_customer_email") !==
                "travelServices.travel_services_customer_email"
                  ? t("travelServices.travel_services_customer_email")
                  : "Email *"
              }
              value={values.email || ""}
              onChange={(e) => setFieldValue("email", e.target.value)}
              required
              inputProps={{
                "data-lpignore": "true",
              }}
              className={
                errors.email && touched.email ? "error-input" : "noerror-input"
              }
              variant="standard"
            />
          </Grid>
          <Grid item xs={12}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                height: 15,
                marginLeft: 5,
                marginTop: 3,
              }}
            >
              <span style={{ fontSize: 12 }}>
                {/* {t(`auth.send_confirm_booking`) !== "auth.send_confirm_booking"
              ? t(`auth.send_confirm_booking`)
              : "Send my confirmation booking via:"} */}
                Contact me via:
              </span>
              <Checkbox
                icon={
                  <img
                    src={GrayCircleIcon}
                    alt="checked"
                    style={{ width: 15, height: 15 }}
                  />
                }
                checkedIcon={
                  <img
                    src={GreenCircleIcon}
                    alt="unchecked"
                    style={{ width: 15, height: 15 }}
                  />
                }
                checked={values.isCheckSMS}
                name="isSignUp"
                onChange={(e) => {
                  // if (values.phonePrefix === "95") {
                  //   setFieldValue("isCheckSMS", e.target.checked);
                  //   if (!values?.isCheckEmail) {
                  //     setFieldValue("isCheckEmail", true);
                  //   }
                  // } else {
                  //   setFieldValue("isCheckSMS", e.target.checked);
                  // }
                  setFieldValue("isCheckSMS", !values.isCheckSMS);
                  setFieldValue("isCheckEmail", !values.isCheckEmail);
                }}
                value={values.isCheckSMS}
                className="isSmsChecked"
                disabled={values.phonePrefix !== "95" ? true : false}
              />
              <span style={{ fontSize: 12 }}>
                {t(`travelServices.contact_type_phone`) !==
                "travelServices.contact_type_phone"
                  ? t(`travelServices.contact_type_phone`)
                  : "Phone"}
                {/* {t(`auth.sms`) !== "auth.sms" ? t(`auth.sms`) : "SMS"} */}
              </span>
              <Checkbox
                icon={
                  <img
                    src={GrayCircleIcon}
                    alt="checked"
                    style={{ width: 15, height: 15 }}
                  />
                }
                checkedIcon={
                  <img
                    src={GreenCircleIcon}
                    alt="unchecked"
                    style={{ width: 15, height: 15 }}
                  />
                }
                checked={values.isCheckEmail}
                name="isSignUp"
                onChange={(e) => {
                  // if (values.phonePrefix === "95") {
                  //   setFieldValue("isCheckEmail", e.target.checked);
                  //   if (!values?.isCheckSMS) {
                  //     setFieldValue("isCheckSMS", true);
                  //   }
                  // } else {
                  //   setFieldValue("isCheckEmail", e.target.checked);
                  // }
                  setFieldValue("isCheckEmail", !values.isCheckEmail);
                  setFieldValue("isCheckSMS", !values.isCheckSMS);
                }}
                value={values.isCheckEmail}
                className="isEmailChecked"
                disabled={values.phonePrefix !== "95" ? true : false}
              />
              <span style={{ fontSize: 12 }}>
                {t(`b2c.email`, { ns: "b2c" }) !== "b2c.email"
                  ? t(`b2c.email`, { ns: "b2c" })
                  : "Email"}
              </span>
            </div>
          </Grid>
          <Grid item xs={12} className={`${classes.submitButton} ${clientCss}`}>
            <Button
              color={
                clientTag === KBZSC_IN_WEB ||
                clientTag === VIBER_IN_WEB ||
                clientTag === WAVEMONEY ||
                clientTag === KBZ
                  ? "info"
                  : "primary"
              }
              variant="contained"
              onClick={() => handleSubmit()}
              disabled={isSubmitting}
              className="btnSearch"
            >
              {t("travelServices.send_inquiry") !==
              "travelServices.send_inquiry"
                ? t("travelServices.send_inquiry")
                : "Send Inquiry"}
            </Button>
          </Grid>
        </Grid>
      </Modal>
    </React.Fragment>
  );
};

export default compose(
  withFormik({
    mapPropsToValues: (props) => {
      return {
        guest_no: "",
        duration_of_stay: "",
        title: "Mr",
        name: "",
        last_name: "",
        phoneCountryFlag: "mm",
        phonePrefix: "95",
        phone: "",
        email: "",
        isCheckEmail: false,
        isCheckSMS: true,
      };
    },

    validationSchema: (props) => HotelValidationSchema(props),

    handleSubmit: (values, { props, resetForm }) => {
      props.handleReservation(values, resetForm);
    },

    displayName: "BasicForm",
  }),
)(HotelPassengerFormMobile);
