import React from "react";
import { useTranslation } from "react-i18next";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";

const HotelSortDropdown = ({ sortByValue, handleSortBy, from }) => {
  const { t } = useTranslation(["2Search", "hotel"]);

  return (
    <div
      className="d-flex align-items-center justify-content-end"
      id="hotelSortBy"
      style={{ gap: "5px" }}
    >
      {from && from === "hotel" && (
        <span>
          {t("2Search.sort_by") !== "2Search.sort_by"
            ? t("2Search.sort_by")
            : "Sort By"}{" "}
          :{" "}
        </span>
      )}
      <div
        className="dropdown d-flex align-items-center"
        onClick={() =>
          handleSortBy(
            sortByValue === "lowest_price" ? "highest_price" : "lowest_price",
          )
        }
      >
        <span>Price</span>
        {sortByValue === "lowest_price" ? (
          <ExpandMoreIcon />
        ) : (
          <ExpandLessIcon />
        )}
      </div>
    </div>
  );
};

export default HotelSortDropdown;
